<template>
    <div class="content">
        <template v-if="loaded">
            <div class="header">
                <div class="title">{{ keyResult.name }}</div>
                <slot name="header_menu_right"></slot>
            </div>

            <div class="sub-header-info">
                <div class="data">
                    <div class="label">{{ $t('general.responsible')}}</div>
                    <div class="data-info">
                        <span class="text">{{ keyResult.responsible.name }}</span>
                    </div>
                </div>
                
                <div class="data">
                    <div class="label">{{ $t('general.progress')}}</div>
                    <div class="data-info">
                        <span class="text">{{ keyResult.percent }}%</span>
                    </div>
                </div>

                <div class="data">
                    <div class="label">{{ $t('show_user.last_value')}}</div>
                    <v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
                        <div class="data-info">
                            <span class="text">{{ returnValue(keyResult.last_key_result_log.value) }} {{ keyResult.unity }}</span>
                        </div>

                        <template slot="popover">
                            <div class="simple-text">{{ $t('show_user.key_result_progress_type.' + keyResult.progress_type, { to: keyResult.target % 1 != 0 ? $options.filters.numeral(keyResult.target ,  '0,0.00') : $options.filters.numeral(keyResult.target ,  '0,0'), unit: keyResult.unity, from: keyResult.start_value % 1 != 0 ? $options.filters.numeral(keyResult.start_value ,  '0,0.00') : $options.filters.numeral(keyResult.start_value ,  '0,0')}) }}</div>
                        </template>
                    </v-popover>
                </div>
                <div class="data">
                    <span class="label">{{ $t('general.last_update') }}</span>
                    <v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
                        <div class="value" v-if="keyResult.needs_an_update == 'today' || keyResult.needs_an_update == 'past'" @click="($auth.check && keyResult.rights.can_create_log) ? openModal('add_kr_update', keyResult) : ''" :class="{is_link: keyResult.rights.can_create_log}">
                            <icon-circle-check class="orange"/>
                            <span class="text">{{ $t('key_results.status-pending') }}</span>
                        </div>
                        <div class="value" v-else-if="keyResult.last_key_result_log"  @click="($auth.check && keyResult.rights.can_create_log) ? openModal('kr_updates', keyResult) : ''" :class="{is_link: keyResult.rights.can_create_log}">
                            <icon-circle-check class="green"/>
                            <span class="text">{{ $tc('key_results.days-ago', diffDaysFrom(keyResult.last_key_result_log.created_at)) }}</span>
                        </div>
                        <div class="value" v-else @click="($auth.check && keyResult.rights.can_create_log) ? openModal('add_kr_update', keyResult) : ''" :class="{is_link: keyResult.rights.can_create_log}">
                            <span class="text">{{ $t('key_results.no-updates') }}</span>
                        </div>

                        <template slot="popover">
                            <div class="simple-text" v-if="keyResult.needs_an_update == 'today'">{{ $t('key_results.tooltip-update-today') }}</div>
                            <div class="simple-text" v-else-if="keyResult.needs_an_update == 'past'">{{ $t('key_results.tooltip-update-past') }}</div>
                            <div class="simple-text" v-else-if="keyResult.needs_an_update">{{ $tc('key_results.tooltip-update-future', keyResult.needs_an_update) }}</div>
                            <div class="simple-text" v-else-if="keyResult.status == 'upcoming'">{{ $t('key_results.tooltip-update-cant') }}</div>
                            <div class="simple-text" v-else>{{ $tc('key_results.tooltip-updates-view') }}</div>
                        </template>
                    </v-popover>
                </div>
                <div class="data">
                    <span class="label">{{ $t('show_user.deadline')}}</span>
                    <div class="data-info">
                        <span class="text">{{ moment(keyResult.deadline).format('DD MMM YYYY') }}</span>
                    </div>
                </div>
            </div>
            
            <div class="body">
                <div class="container-form">
                    <div class="table-list">
                        <template v-for="(task, index) in keyResult.tasks">
                            <RowTask :task="task" :keyResultId="keyResult.id" :index="index" :extraInfo="['update', 'key_result', 'date']" fromStep="key_result" v-if="$auth.check() && ($parent.can_lead || (keyResult.responsible ? keyResult.responsible.id == $auth.user().id : false))"/>
                            <ReadTask :task="task" :extraInfo="['key_result', 'date']" fromStep="key_result" v-else/>
                        </template>

                        <div class="row-item add-item" v-if="$auth.check() && ($parent.can_lead || (keyResult.responsible ? keyResult.responsible.id == $auth.user().id : false))">
                            <div class="column-icon">
                                <icon-plus />
                            </div>
                            <div class="column-input">
                                <input type="text" :placeholder="$t('dashboard.placeholder_add_task')" v-model="new_task.name" @keyup.enter="storeTask"/>
                            </div>
                            <div class="column-input-date">
                                <div class="deadline-calendar top">
                                    <InputCalendarPicker :data="new_task.deadline" @updateDate="(deadline) => { new_task.deadline = deadline.value }"/>
                                </div>
                            </div>

                            <div class="column-button">
                                <button class="btn-tbf blue center" @click="storeTask">
                                    <div class="text">{{ $t('general.add') }}</div>
                                </button>
                            </div>

                            <div class="row-info-action" v-if="new_task.name">
                                <div class="text">{{ $t('meeting_session.remainder_add_task') }}</div>
                            </div>
                        </div>
                    </div>
                    <slot name="form_submit"></slot>
                </div>
            </div>
        </template>
        <loader v-else/>
    </div>
</template>

<script>
import IconTask from '@/components/Icons/Task'
import IconCheck from '@/components/Icons/Check'
import IconCircleCheck from '@/components/Icons/CircleCheck'
import IconFlag from '@/components/Icons/Flag'
import IconPlus from '@/components/Icons/Plus'

import Loader from '@/components/PagesLoaders/MeetingKeyResult'
import RowTask from '@/components/ComponentsTask/RowTask'
import InputCalendarPicker from '@/components/ComponentsTask/CalendarPicker'
import ReadTask from '@/components/ComponentsTask/ReadTask'
import InputUserSelect from '@/components/ComponentsTask/UserSelect'

export default {
    components: {
        IconTask,
        IconCheck,
        IconFlag,
        IconCircleCheck,
        IconPlus,
        Loader,
        RowTask,
        InputCalendarPicker,
        InputUserSelect,
        ReadTask
    },
    props: {
        optionsUsers: Array,
        data: Object|Boolean
    },
    watch: {
        data(newValue) {
            this.loaded = false;
            this.getKeyResultData();
        }
    },
    beforeDestroy() {
        this.$root.$off("updateTaskKeyResultMeeting");
    },
    data() {
        return {
            loaded: false,
            loadedFinish: false,
            typeSubject: 'evaluation',
            guests: [],
            keyResult: {},
            new_task: {
                name: '',
                deadline: '',
                responsible: ''
            }
        }
    },
    async mounted() {
        await this.getKeyResultData();

        this.$root.$on('updateTaskKeyResultMeeting', (taskData) => {
            this.addTaskFromWebsocket(taskData);
        });
    },
    methods: {
        async getKeyResultData(){
            await axios.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/public/${this.$parent.masterInstanceId}/key-results/${this.data.id}/tasks`)
            .then(({data}) => {
                this.keyResult = data.data;
            }).finally(() => {
                this.loaded = true;
            })
        },
        changeStepFunction(direction, stepNo = false) {
            if(direction) { 
                this.$emit(direction);
            } else if(stepNo) {
                this.$emit('goToStep', stepNo)
            }
        },
        changeStepOrRedirect(changeStep, withRedirect, userSlug) {
            if(changeStep) {
                this.$nextTick(() => {
                    if(!changeStep.step) {
                        this.$emit(changeStep.direction);
                    } else {
                        this.$emit('goToStep', changeStep.step)
                    }
                });
            }

            if(withRedirect) {
                if(this.$route.query.department_id){
                    this.$router.push({name: 'company'})
                }else{
                    this.$router.push({name: 'user-show', params: { slug: userSlug }})
                }
            }
        },
        addTaskFromWebsocket(taskData) {
            switch (taskData.action) {
                case 'store':
                    this.keyResult.tasks.push(taskData);
                    break;
                case 'update':
                    // find task and update it
                    var task = this.keyResult.tasks.find(el => el.id == taskData.task_id);
                    if( task ) {
                        task.name = taskData.name;
                        task.deadline = taskData.deadline;
                        task.done = taskData.done;
                    }
                    break;
            }
        },
        returnValue(value){
            const roundedNumber = Math.round(value * 100) / 100; // Round to 2 decimal places

            if (Number.isInteger(roundedNumber)) {
            return roundedNumber.toFixed(0); // Remove decimal places for integers
            } else {
            return roundedNumber.toFixed(2); // Preserve 2 decimal places for non-integers
            }
        },
        diffDaysFrom(update_date){
            var a = moment(update_date).startOf('day');
            var b = moment().startOf('day');
            return b.diff(a, 'days');  
        },
        storeTask() {
            if(this.new_task.name != '') {
                var formData = {
                    name: this.new_task.name,
                    key_result_id: this.keyResult.id,
                    from_meeting_id: this.$parent.meetingLog.id,
                    from_meeting_step: 'key_result'
                };

                if(this.new_task.deadline != '') {
                    formData.deadline = this.new_task.deadline;
                } else {
                    formData.deadline = moment().format('YYYY-MM-DD');
                }

                axios.post(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/${this.keyResult.id}/tasks/store`, formData)
                .then(({data}) => {
                    const newTask = {...formData, id: data.data.id, responsible: this.optionsUsers.find(el => el.id == this.$auth.user().id), done: 0};

                    this.keyResult.tasks.push(newTask);

                    this.$parent.socket.send(JSON.stringify({
                        'entity_id': this.$parent.meetingLog.id,
                        'entity_user_id': this.$auth.user().id,
                        'type': 'meeting',
                        'custom_data': {
                            'type': 'kr_task',
                            'data': {
                                ...newTask,
                                'action': 'store'
                            }
                        }
                    }));

                    this.new_task = { name: '', deadline: '', responsible: '' }
                })
            }
        },
        openModal(type, keyResult){
            this.$root.$emit('open_modal_full_template', type, {
                keyResult: keyResult,
                meetingId: this.$parent.meetingLog.id
            })
        },
    }
}
</script>
