<template>
    <div class="sidebar-right-meeting">
        <div class="header-sidebar">
            <div class="title-sidebar">{{ $t('meeting_session.subjects_topic') }}</div>
            <div class="search-topic">
                <div class="icon-left"><icon-search /></div>
                <input type="text" class="input-text" :placeholder="$t('general.search_by_name')" v-model="search_topic" v-debounce:500="getTopic">
            </div>
        </div>
        
        <div class="list-content">
            <template v-if="!isLoading">
                <div class="category-accordion" v-if="projects.length">
                    <div class="header-accordion collapsed" data-toggle="collapse" :data-target="`#collapseProjects`" aria-expanded="true">
                        <div class="category-header">
                            <div class="icon-left">
                                <icon-projects />
                            </div>
                            <div class="title">{{ $t('general.projects') }}</div>
                
                            <button class="arrow-collapse show" :class="{collapsed: !projects.length}" type="button" data-toggle="collapse" :data-target="`#collapseProjects`" aria-expanded="true">
                                <icon-arrow />
                            </button>
                        </div>
                    </div>
        
                    <div class="content-accordion collapse" :class="{show: projects.length}" :id="`collapseProjects`">
                        <template v-if="projects.length">
                            <div class="category-item" v-for="project in projects" @click="toggleCheckTopic('project', project.id)">
                                <div class="checkbox-tbf-special" :class="{active: optionsSelected.find(el => el.type == 'project' && el.id == project.id)}">
                                    <icon-check />
                                </div>
                                <div class="item-name">
                                    {{ project.name }}
                                </div>
                            </div>
                        </template>
                        <div class="not-found-items" v-else>
                            {{ $t('general.no_results') }}
                        </div>
                    </div>
                </div>
    
                <div class="category-accordion" v-if="objectives.length">
                    <div class="header-accordion collapsed" data-toggle="collapse" :data-target="`#collapseObjectives`" aria-expanded="true">
                        <div class="category-header">
                            <div class="icon-left">
                                <icon-objectives />
                            </div>
                            <div class="title">{{ $t('meeting_session.topic.objectives') }}</div>
                
                            <button class="arrow-collapse show" :class="{collapsed: !objectives.length}" type="button" data-toggle="collapse" :data-target="`#collapseObjectives`" aria-expanded="true">
                                <icon-arrow />
                            </button>
                        </div>
                    </div>
        
                    <div class="content-accordion collapse" :class="{show: objectives.length}" :id="`collapseObjectives`">
                        <template v-if="objectives.length">
                            <div class="category-item" v-for="objective in objectives" @click="toggleCheckTopic('objective', objective.id)">
                                <div class="checkbox-tbf-special" :class="{active: optionsSelected.find(el => el.type == 'objective' && el.id == objective.id)}">
                                    <icon-check />
                                </div>
                                <div class="item-name">
                                    {{ objective.name }}
                                </div>
                                <div v-if="objective.end_date" class="date">
                                    {{ moment(objective.end_date).format('DD MMM') }}
                                </div>
                            </div>
                        </template>
                        <div class="not-found-items" v-else>
                            {{ $t('general.no_results') }}
                        </div>
                    </div>
                </div>
    
                <div class="category-accordion" v-if="key_results.length">
                    <div class="header-accordion collapsed" data-toggle="collapse" :data-target="`#collapseKeyResults`" aria-expanded="true">
                        <div class="category-header">
                            <div class="icon-left">
                                <icon-key-result />
                            </div>
                            <div class="title">{{ $t('meeting_session.topic.key_results') }}</div>
                
                            <button class="arrow-collapse show" :class="{collapsed: !objectives.length}" type="button" data-toggle="collapse" :data-target="`#collapseKeyResults`" aria-expanded="true">
                                <icon-arrow />
                            </button>
                        </div>
                    </div>
        
                    <div class="content-accordion collapse" :class="{show: key_results.length}" :id="`collapseKeyResults`">
                        <template v-if="key_results.length">
                            <div class="category-item" v-for="key_result in key_results" @click="toggleCheckTopic('key_result', key_result.id)">
                                <div class="checkbox-tbf-special" :class="{active: optionsSelected.find(el => el.type == 'key_result' && el.id == key_result.id)}">
                                    <icon-check />
                                </div>
                                <div class="item-name">
                                    {{ key_result.name }}
                                </div>
                                <div v-if="key_result.end_date" class="date">
                                    {{ moment(key_result.end_date).format('DD MMM') }}
                                </div>
                            </div>
                        </template>
                        <div class="not-found-items" v-else>
                            {{ $t('general.no_results') }}
                        </div>
                    </div>
                </div>
    
                <div class="category-accordion" v-if="tasks.length">
                    <div class="header-accordion collapsed" data-toggle="collapse" :data-target="`#collapseTasks`" aria-expanded="true">
                        <div class="category-header">
                            <div class="icon-left">
                                <icon-task />
                            </div>
                            <div class="title">{{ $t('meeting_session.topic.tasks') }}</div>
                
                            <button class="arrow-collapse show" :class="{collapsed: !tasks.length}" type="button" data-toggle="collapse" :data-target="`#collapseTasks`" aria-expanded="true">
                                <icon-arrow />
                            </button>
                        </div>
                    </div>
        
                    <div class="content-accordion collapse" :class="{show: tasks.length}" :id="`collapseTasks`">
                        <template v-if="tasks.length">
                            <div class="category-item" v-for="task in tasks" @click="toggleCheckTopic('task', task.id)">
                                <div class="checkbox-tbf-special" :class="{active: optionsSelected.find(el => el.type == 'task' && el.id == task.id)}">
                                    <icon-check />
                                </div>
                                <div class="item-name">
                                    {{ task.name }}
                                </div>
                                <div v-if="task.deadline" class="date">
                                    {{ moment(task.deadline).format('DD MMM') }}
                                </div>
                            </div>
                        </template>
                        <div class="not-found-items" v-else>
                            {{ $t('general.no_results') }}
                        </div>
                    </div>
                </div>
    
                <div class="category-accordion" v-if="['standard', 'custom_topic'].includes($parent.meetingLog.topic_type)">
                    <div class="header-accordion collapsed" data-toggle="collapse" :data-target="`#collapseCustomTopic`" aria-expanded="true">
                        <div class="category-header">
                            <div class="icon-left">
                                <icon-custom />
                            </div>
                            <div class="title">{{ $t('meeting_session.topic.custom_topic') }}</div>
                
                            <button class="arrow-collapse show" type="button" data-toggle="collapse" :data-target="`#collapseCustomTopic`" aria-expanded="true">
                                <icon-arrow />
                            </button>
                        </div>
                    </div>
        
                    <div class="content-accordion collapse show" :id="`collapseCustomTopic`">
                        <template v-if="customOptions.length">
                            <div class="category-item" v-for="customOption in customOptions" @click="toggleCheckTopic('custom', customOption.uuid, customOption.name)">
                                <div class="checkbox-tbf-special" :class="{active: optionsSelected.find(el => el.type == 'custom' && el.name === customOption.name)}">
                                    <icon-check />
                                </div>
                                <div class="item-name">
                                    {{ customOption.name }}
                                </div>
                            </div>
                        </template>
                        <div class="custom-topic">
                            <textarea type="text" class="input-text" :placeholder="$t('meeting_session.placeholder_write_topic')" v-model="custom_topic"></textarea>
                            <button class="add-custom-topic" @click="storeCustomTopic">{{ $t('meeting_session.store_custom_topic') }}</button>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import IconSearch from '@/components/Icons/Search'
import IconCheck from '@/components/Icons/Check'
import IconArrow from '@/components/Icons/Arrow'
import IconProjects from '@/components/Icons/Projects'
import IconObjectives from '@/components/Icons/Objectives'
import IconTask from '@/components/Icons/Task'
import IconCheckList from '@/components/Icons/CheckList'
import IconCustom from '@/components/Icons/Edit'
import IconKeyResult from '@/components/Icons/KeyResult'
import { debounce } from "debounce";

export default {
    props: {
        currentTopic: Array
    },
    components: {
        IconSearch,
        IconCheck,
        IconArrow,
        IconProjects,
        IconObjectives,
        IconTask,
        IconCheckList,
        IconCustom,
        IconKeyResult
    },
    created() { 
        this.handleDebounceTopic = debounce( () => { this.updateTopic(); }, 1000)
    },
    data() {
        return {
            isLoading: true,
            projects: [],
            objectives: [],
            key_results: [],
            tasks: [],
            search_topic: '',
            optionsSelected: [],
            customOptions: [],
            custom_topic: ''
        }
    },
    async mounted() {
        this.optionsSelected = [...this.currentTopic];

        if(this.optionsSelected.find(el => el.type == 'custom')) {
            this.customOptions = this.optionsSelected.filter(el => el.type === 'custom').map(el => ({uuid: el.uuid, name: el.name})) ;
        }

        this.$root.$on('updateSidebarTopics', (topicsData) => {
            this.optionsSelected = topicsData;
        })

        await this.getTopic();
    },
    beforeDestroy() {
        this.$root.$off("updateSidebarTopics");
    },
    methods: {
        async getTopic() { 
            var objectivesReady = false;
            var organigramaReady = false;
            const searchTopic = this.search_topic;

            var paramsQueryOrg = {};
            var paramsQueryObj = {};

            switch (this.$parent.meetingLog.topic_type) {
                case 'project':
                        paramsQueryOrg.projects = true;
                    break;
                case 'objective':
                        paramsQueryObj.objectives = true;
                    break;
                // case 'daily_tasks':
                //         paramsQueryOrg.tasks = true;
                //     break;
                case 'task':
                        paramsQueryOrg.tasks = true;
                    break;
                case 'key_result':
                        paramsQueryObj.key_results = true;
                    break;
                case 'standard':
                        paramsQueryOrg.projects = true;
                        paramsQueryOrg.tasks = true;
                        paramsQueryObj.objectives = true;
                        paramsQueryObj.key_results = true;
                    break;
                }

            // Get Objectives
            axios.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/instances/${this.$auth.user().master_instance_id}/filter/external`, { params: { ...paramsQueryObj, search_by: searchTopic } })
            .then(({data}) => {
                this.objectives = data.data.objectives ? data.data.objectives : [];
                this.key_results = data.data.key_results ? data.data.key_results : [];
            })
            .finally(() => {
                objectivesReady = true;
            })

            // Get projects
            axios.get(`/instances/${this.$auth.user().master_instance_id}/filter/external`, { params: { ...paramsQueryOrg, search_by: searchTopic } })
            .then(({data}) => {
                this.projects = data.data.projects ? data.data.projects : [];
                this.tasks = data.data.tasks ? data.data.tasks : [];
            })
            .finally(() => {
                organigramaReady = true;
            })

            var checkReadyArray = setInterval(() => {
                if(objectivesReady && organigramaReady){
                    this.isLoading = false;
                    clearInterval(checkReadyArray);
                }
            }, 50);
        },
        toggleCheckTopic(type, itemId = false, itemName = false) {
            var foundItem = '';
            var objectToPush = { type: type };

            if( type == 'custom') {
                objectToPush.uuid = itemId;
                objectToPush.name = itemName;
                foundItem = this.optionsSelected.find(el => el.type == type && el.uuid == itemId);
            } else if(itemId) { 
                objectToPush.id = itemId;
                foundItem = this.optionsSelected.find(el => el.type == type && el.id == itemId);
            } else {
                foundItem = this.optionsSelected.find(el => el.type == type);
            }

            if(foundItem) { 
                const foundIndex = this.optionsSelected.indexOf(foundItem);
                this.optionsSelected.splice(foundIndex, 1);
            } else {
                if(this.$parent.meetingLog.topic_type == 'project' && type == 'project') {
                    this.optionsSelected = this.optionsSelected.filter(el => el.type != 'project');
                } else if(this.$parent.meetingLog.topic_type == 'objective' && type == 'objective') {
                    this.optionsSelected = this.optionsSelected.filter(el => el.type != 'objective');
                }
                
                this.optionsSelected.push(objectToPush)
            }

            this.handleDebounceTopic();
        },
        storeCustomTopic() {
            if(this.custom_topic) { 
                var topicUuid = this.generateUniqueId();
                this.customOptions.push( {name: this.custom_topic, id: topicUuid} );

                this.toggleCheckTopic('custom', topicUuid, this.custom_topic);

                this.custom_topic = '';
            }
        },
        updateTopic() {
            var arraySortByConst = this.optionsSelected.slice().sort(compareByType);

            axios.patch(`/meeting-session/${this.$parent.meetingSessionId}/update`, { topic: arraySortByConst})
            .then(({data}) => {
                this.$parent.meetingTopic = data.data.topic;
                this.$parent.dataComponent.subject = data.data.topic;
                this.$emit('updateTopic');
            })
        },
        generateUniqueId() {
            const timestamp = new Date().getTime().toString(36);
            const randomStr = Math.random().toString(36).substr(2, 5);
            return timestamp + randomStr;
        }
    }
}

// sort order array for the TOPIC
const order = [
    "past_day_evaluation",
    "project",
    "objective",
    "key_result",
    "task",
    "custom",
    "weekly_evaluation"
];

function compareByType(a, b) {
    const typeA = a.type;
    const typeB = b.type;
    const indexA = order.indexOf(typeA);
    const indexB = order.indexOf(typeB);

    if (indexA === -1 && indexB === -1) {
        return 0;
    } else if (indexA === -1) {
        return 1;
    } else if (indexB === -1) {
        return -1;
    }

    return indexA - indexB;
}

</script>